<template>
  <div>
    <action-menu :actions="actions" @click="onActionClick($event, row)" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActionMenu from '@/components/organisms/shared/ActionMenu.vue';
import {
  SET_DATA_REPORT_MODAL,
  SET_STATE_REPORT_MODAL,
  SET_TYPE_REPORT_MODAL,
} from '@/store/modules/campaignManager/keys';

export default {
  name: 'CampaignManagerActions',
  components: { ActionMenu },
  props: {
    data: {
      type: [String, Array, Object],
      default: () => null,
    },
  },
  data() {
    return {
      actions: [
        { id: 'standard', name: 'Create report gross xls' },
        { id: 'custom', name: 'Create report curated xls' },
      ],
    };
  },
  computed: {
    row() {
      return this.data;
    },
  },
  created() {},
  methods: {
    ...mapActions({
      setShowReportModal: SET_STATE_REPORT_MODAL,
      setDataReportModal: SET_DATA_REPORT_MODAL,
      setTypeReportModal: SET_TYPE_REPORT_MODAL,
    }),
    onActionClick(event, item) {
      this.openReloadModal(event.id, item);
    },
    openReloadModal(type, row) {
      this.setShowReportModal(true);
      this.setDataReportModal(row);
      this.setTypeReportModal(type);
    },
  },
};
</script>
