var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex w-full flex-row flex-nowrap items-center pl-1 justify-start",
      class: { "justify-center": _vm.isLoading },
    },
    [
      !_vm.isLoading
        ? _c("checkbox-simple", {
            attrs: {
              label: _vm.reportSent,
              "can-open": false,
              color: "orange",
              selected: _vm.selectReportSent,
              "text-color": "white",
            },
            on: { change: _vm.changeDateReportSent },
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("sun-loading", { staticClass: "cell-report-sent" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }