<template>
  <div class="flex items-center w-full">
    <sun-arrow-down-svg v-if="hasProducts" class="absolute" />
    <span v-if="notHasProducts" class="absolute text-left">-</span>
    <span
      class="relative truncate text-left left-1-5rem"
      :class="{ 'left-1rem': notHasProducts, 'font-bold': isCampaign }"
      :title="value"
    >
      {{ value || '-' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CampaignNameCell',
  props: {
    data: {
      type: [Object, null],
      default: () => null,
    },
  },
  data() {
    return {
      value: this.data.name,
    };
  },
  computed: {
    isCampaign() {
      return this.data.type === 'Campaign';
    },
    hasProducts() {
      return this.isCampaign && this.data.hasProducts;
    },
    notHasProducts() {
      return this.isCampaign && !this.data.hasProducts;
    },
  },
};
</script>

<style scoped>
.left-1-5rem {
  left: 1.5rem;
}
.left-1rem {
  left: 1rem !important;
}
</style>
