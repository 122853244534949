import { api } from '..';
import { SALESFORCE_OPPORTUNITY_RESOURCE } from '@/services/keys';

/**
 *
 * @param id
 * @param opportunity
 * @returns {Promise<AxiosResponse<*>>}
 */
export async function updateOpportunity(id, opportunity = {}) {
  const partialUrl = api.createUrl({
    [SALESFORCE_OPPORTUNITY_RESOURCE]: id,
  });

  return await api.update(partialUrl, opportunity);
}
