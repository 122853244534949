<template>
  <div
    class="flex w-full flex-row flex-nowrap items-center pl-1 justify-start"
    :class="{ 'justify-center': isLoading }"
  >
    <checkbox-simple
      v-if="!isLoading"
      :label="reportSent"
      :can-open="false"
      color="orange"
      :selected="selectReportSent"
      text-color="white"
      @change="changeDateReportSent"
    />
    <sun-loading v-if="isLoading" class="cell-report-sent" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { updateProductCustomFields } from '@/services/modules/Core/salesforce/product';
import { updateOpportunityCustomFields } from '@/services/modules/Core/salesforce/opportunity';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { dateToYYYYMMDD } from '@/filters/dateFilters';
import CheckboxSimple from '@/components/organisms/modules/socialAudience/campaign/form/targeting/MultiCheckboxTree/CheckboxSimple.vue';

export default {
  name: 'CampaignManagerReportSentCheckbox',
  components: { CheckboxSimple },
  props: {
    data: {
      type: [String, Array, Object],
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      selectReportSent: false,
    };
  },
  computed: {
    reportSent() {
      return this.data.reportSent ?? '-';
    },
  },
  mounted() {
    this.selectReportSent = this.data?.reportSent !== '-';
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async changeDateReportSent() {
      this.isLoading = true;
      this.selectReportSent = !this.selectReportSent;
      try {
        this.data.reportSent = this.selectReportSent ? dateToYYYYMMDD(new Date()) : '-';

        const payload = {
          status: this.data.status,
          reportSent: this.data.reportSent !== '-' ? this.data.reportSent : null,
        };
        if (this.data.type === 'Product') {
          await updateProductCustomFields(this.data.id, payload);
        } else {
          await updateOpportunityCustomFields(this.data.id, payload);
        }

        await this.createToast(Toast.success(`The report sent updated`));
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          await this.createToast(Toast.error(`Can not update the report sent`, error.message));
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
::v-deep .multicheckbox .text-base {
  font-size: 12px;
  font-weight: normal;
}

.cell-report-sent {
  justify-content: center;
  color: #ed8936;
  height: 1.5rem;
  width: 1.5rem;
}
</style>
