var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "w-full text-left truncate",
      attrs: { to: _vm.routeToNavigate },
    },
    [
      _c(
        "span",
        {
          staticClass: "w-full underline font-bold",
          attrs: { title: _vm.value },
        },
        [_vm._v(_vm._s(_vm.value || "N/A"))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }