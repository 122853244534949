var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full h-full flex items-center justify-center" },
    [
      _vm.isHiperLink
        ? _c("hyperlink-cell", {
            attrs: {
              value: _vm.value,
              route: _vm.productMetrics,
              "param-name": "productId",
              "param-value": _vm.data.id,
            },
          })
        : _vm.isTextCell
        ? _c("text-cell", {
            class: {
              "font-bold": _vm.isBold,
            },
            attrs: { value: _vm.value },
          })
        : _vm.isNumericCell
        ? _c("numeric-cell", { attrs: { value: _vm.value } })
        : _vm.isPercentageCell
        ? _c("percentage-cell", { attrs: { value: _vm.value } })
        : _vm.isCurrencyAndEurCell
        ? _c("currency-cell", { attrs: { value: _vm.value, currency: "EUR" } })
        : _vm.isCurrencyAndUsdCell
        ? _c("currency-cell", { attrs: { value: _vm.value, currency: "USD" } })
        : _vm.isDateCell
        ? _c("date-cell", { attrs: { value: _vm.value } })
        : _vm.isSelectCampaignManagerActionCell
        ? _c("campaign-manager-status-action-select", {
            attrs: { "grid-api": _vm.gridApi, data: _vm.data },
          })
        : _vm.isSFCampaign
        ? _c("campaign-name-cell", { attrs: { data: _vm.data } })
        : _vm.isActionCell
        ? _c("campaign-manager-actions", { attrs: { data: _vm.data } })
        : _vm.isReportSentCell
        ? _c("campaign-manager-report-sent-checkbox", {
            attrs: { data: _vm.data },
          })
        : _c("span", { staticClass: "bg-red-200" }, [
            _vm._v(_vm._s(_vm.column) + "--" + _vm._s(_vm.value)),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }