export const SETTINGS_ANALYTICS = 'analytics';
export const SETTINGS_ANALYTICS_DIRECT = 'direct';
export const SETTINGS_MAPS_COLUMNS = 'maps_columns';
export const SETTINGS_PLATFORM_ALERT = 'show_platform_alert';
export const TIMEZONE = 'timezone';
export const CAMPAIGN_MANAGER = 'campaign_manager';
export const ORDER_COLUMNS_DIRECT = 'order_columns_direct';

const AVAILABLE_SETTINGS = {
  ANALYTICS_DIRECT: `${SETTINGS_ANALYTICS}-${SETTINGS_ANALYTICS_DIRECT}`,
  ANALYTICS: SETTINGS_ANALYTICS,
  MAPS_COLUMNS: SETTINGS_MAPS_COLUMNS,
  SHOW_PLATFORM_ALERT: SETTINGS_PLATFORM_ALERT,
  TIMEZONE,
  ORDER_COLUMNS_DIRECT,
};

export default AVAILABLE_SETTINGS;
