<template>
  <router-link :to="routeToNavigate" class="w-full text-left truncate">
    <span class="w-full underline font-bold" :title="value">{{ value || 'N/A' }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'HyperlinkCell',
  props: {
    value: {
      type: [String, null],
      default: () => '',
    },
    route: {
      type: Object,
      default: () => {},
    },
    paramName: {
      type: String,
      default: () => '',
    },
    paramValue: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    routeToNavigate() {
      return { name: this.route.name, params: { [this.paramName]: this.paramValue } };
    },
  },
};
</script>

<style scoped></style>
