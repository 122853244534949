export const BREAKDOWN_VALUES = {
  product: 'product',
  structure: 'structure',
  delivery: 'delivery',
  productcampaign: 'productcampaign',
  campaign: 'campaign',
  browser: 'Browser',
  country: 'Country',
  date: 'Date',
  device: 'Device',
  source: 'Source',
  platform: 'platform',
};
