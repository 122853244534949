<template>
  <div class="w-full">
    <div class="w-full table">
      <basic-loader class="m-1 h-10" />
      <basic-loader class="m-1 h-8" />
      <basic-loader class="m-1 pt-3 h-rows" />
      <basic-loader class="m-1 h-10" />
    </div>
  </div>
</template>

<script>
import BasicLoader from '@/components/atoms/Loaders/BasicLoader.vue';

export default {
  name: 'TableLoader',
  components: { BasicLoader },
};
</script>

<style scoped>
.table {
  background-color: white;
}
:deep(.h-rows) {
  height: 24rem;
}
</style>
