<template>
  <div>
    <select
      v-if="isProduct && !isBilled"
      :id="`action-product-${product.id}`"
      v-model="selected"
      class="bg-gray-50 text-gray-900 text-sm w-full p-1"
      @change="changeStatus"
    >
      <option value="-" disabled>-</option>
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.value }}
      </option>
    </select>
    <text-cell v-else-if="isProduct && isBilled" value="Billed" />
    <text-cell v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TextCell from '@/components/atoms/AnalyticsTable/TextCell.vue';
import { updateProductStatus } from '@/services/modules/Core/salesforce/product';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import CONFIG from '@/views/private/modules/thirdParty/campaignManager/config';

export default {
  name: 'CampaignManagerStatusActionSelect',
  components: { TextCell },
  props: {
    gridApi: {
      type: Object,
      default: () => null,
    },
    data: {
      type: [String, Array, Object],
      default: () => null,
    },
  },
  data() {
    return {
      selected: null,
      options: CONFIG.productStatus,
    };
  },
  computed: {
    isProduct() {
      return this.data.type === 'Product';
    },
    isBilled() {
      return this.data?.isBilled?.toUpperCase() === 'YES';
    },
    product() {
      return this.data;
    },
  },
  created() {
    this.selected = this.data.status ?? '-';
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async changeStatus() {
      if (this.selected !== this.data.status && this.selected !== '-') {
        try {
          this.data.status = this.selected;
          this.gridApi.redrawRows();
          await updateProductStatus(this.data.id, this.selected);
          await this.createToast(Toast.success(`Product status updated`));
        } catch (error) {
          if (error.code !== HTTPStatusCode.Cancel) {
            await this.createToast(Toast.error(`Can not update product`, error.message));
          }
        }
      }
    },
  },
};
</script>
