var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center w-full" },
    [
      _vm.hasProducts
        ? _c("sun-arrow-down-svg", { staticClass: "absolute" })
        : _vm._e(),
      _vm.notHasProducts
        ? _c("span", { staticClass: "absolute text-left" }, [_vm._v("-")])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "relative truncate text-left left-1-5rem",
          class: {
            "left-1rem": _vm.notHasProducts,
            "font-bold": _vm.isCampaign,
          },
          attrs: { title: _vm.value },
        },
        [_vm._v(" " + _vm._s(_vm.value || "-") + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }