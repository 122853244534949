var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _c(
      "div",
      { staticClass: "w-full table" },
      [
        _c("basic-loader", { staticClass: "m-1 h-10" }),
        _c("basic-loader", { staticClass: "m-1 h-8" }),
        _c("basic-loader", { staticClass: "m-1 pt-3 h-rows" }),
        _c("basic-loader", { staticClass: "m-1 h-10" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }