// returns "Tue Aug 02 2022" format.
export const dateToString = date => {
  return date ? new Date(date).toDateString() : '-';
};

// returns "2022-08-02 10:00:00.000" format.
export const dateTimeFormat = date => {
  let dateJson = date.toJSON();
  dateJson = dateJson.replace('T', ' ');
  dateJson = dateJson.replace('Z', '');
  return dateJson;
};
// returns "2022-08-02" format.
export const dateWithoutTimeFormat = date => {
  let dateJson = date.toJSON();
  dateJson = dateJson.replace('T', ' ');
  dateJson = dateJson.replace('Z', '');
  dateJson = dateJson.slice(0, 10);
  return dateJson;
};
export const millisecBetween2Dates = (dateOne, dateTwo) => {
  return dateOne.getTime() - dateTwo.getTime();
};

export const checkIfFirstDateIsLessThanSecondDate = (firstDate, secondDate) => {
  return firstDate <= secondDate;
};
